import { useMutation, useQuery } from "@apollo/react-hooks";
import { createStyles, Grid, makeStyles, Theme } from "@material-ui/core";
import Axios from "axios";
import Colors from "Constants/Colors";
import DateConstants from "Constants/DateConstants";
import { Roles } from "Enums/Roles";
import {
  DELETE_VIDEO_SESSION_EVIDENCE,
  GET_VIDEO_SESSION_EVIDENCE,
  GetVideoSessionEvidenceBySessionIdQueryResult,
  GetVideoSessionEvidenceBySessionIdQueryVariables,
  UPDATE_VIDEO_SESSION_EVIDENCE,
} from "Graphql/AuditQueries";
import { keycloak } from "keycloak";
import MaterialTable from "material-table";
import { Audit } from "Models/Audit";
import { VideoSessionEvidenceRow } from "Models/VideoSessionEvidenceRow";
import { VideoSessionRow } from "Models/VideoSessionRow";
import moment from "moment";
import React, { FC, useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { FileUtils } from "Utils/FileUtils";
import { DownloadCloudIcon } from "../Icons/DownloadCloudIcon";
import { TableIcons } from "../Icons/TableIcons";
import { AuditVideoSessionEvidenceDetails } from "./AuditVideoSessionEvidenceDetails";

interface Props {
  readonly videoSessionRow: VideoSessionRow;
  readonly auditData: Audit | undefined;
  setVideoSessionEvidenceAddFormDisabled(): void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
    },
    detailContainer: {
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    detailHeader: {
      fontWeight: "bold",
    },
    editButtonRow: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: theme.spacing(2),
    },
    detailsButtonRow: {
      display: "flex",
      justifyContent: "flex-end",
      marginBottom: theme.spacing(2),
    },
    multiLine: {
      whiteSpace: "pre-line",
    },
    detailBackground: {
      backgroundColor: Colors.ITEM_SELECTED,
    },
  }),
);

export const AuditVideoSessionEvidenceList: FC<Props> = ({
  videoSessionRow,
  auditData,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("audits");
  const [tableData, setTableData] = useState<VideoSessionEvidenceRow[]>([]);
  const [
    selectedVideoSessionEvidenceRow,
    setSelectedVideoSessionEvidenceRow,
  ] = useState<VideoSessionEvidenceRow>();

  const [updateVideoSessionEvidence] = useMutation(
    UPDATE_VIDEO_SESSION_EVIDENCE,
  );
  const [deleteVideoSessionEvidence] = useMutation(
    DELETE_VIDEO_SESSION_EVIDENCE,
  );



  const handleRowDownloadClick = (
    rowData: VideoSessionEvidenceRow | VideoSessionEvidenceRow[],
  ) => {
    let url: string;
    let fileName: string;

    if (Array.isArray(rowData)) {
      url = rowData[0].evidenceReference;
      fileName = `${auditData?.auditReference}-evidence-${rowData[0].createdAt}`;
    } else {
      url = rowData.evidenceReference;
      fileName = `${auditData?.auditReference}-evidence-${rowData.createdAt}`;
    }

    if (url) {
      FileUtils.downloadFileByUrl(url, fileName, `bearer ${keycloak?.token}`);
    }
  };

  const { data: videoSessionData, refetch } = useQuery<
    GetVideoSessionEvidenceBySessionIdQueryResult,
    GetVideoSessionEvidenceBySessionIdQueryVariables
  >(GET_VIDEO_SESSION_EVIDENCE, {
    fetchPolicy: "cache-and-network",
    variables: {
      videoSessionId: videoSessionRow.id || "undefined",
    },
    //onCompleted: handleVideoSessionEvidenceReceived,
  });

  const handleVideoSessionEvidenceReceived = useCallback(() => {
    const videoSessionEvidenceData: VideoSessionEvidenceRow[] = [];
    videoSessionData?.videoSessionEvidence.forEach(item => {
      videoSessionEvidenceData.push({
        ...item,
        submitterName: item.submitter.fullName,
      });
    });
    setTableData(videoSessionEvidenceData);
  // eslint-disable-next-line
  }, [videoSessionData]);

    useEffect(() => {
      handleVideoSessionEvidenceReceived();
    // eslint-disable-next-line
    }, [videoSessionData]);

  const handleRowEdit = (
    updatedVideoSessionEvidenceRow: VideoSessionEvidenceRow,
  ) => {
    updateVideoSessionEvidence({
      variables: {
        id: updatedVideoSessionEvidenceRow.id,
        evidenceDescription: updatedVideoSessionEvidenceRow.description,
      },
    }).then(() => {
      refetch({
        videoSessionId: videoSessionRow.id || "undefined",
      });
    });
  };

  const handleRowDelete = (
    deletedVideoSessionEvidenceRow: VideoSessionEvidenceRow,
  ) => {
    if (deletedVideoSessionEvidenceRow.evidenceReference) {
      Axios.delete(`${process.env.REACT_APP_FILE_SERVER_URL}/file`, {
        headers: { Authorization: `bearer ${keycloak?.token}` },
        params: { fileName: deletedVideoSessionEvidenceRow.evidenceReference },
      }).then(result => {
        if (keycloak?.tokenParsed?.sub) {
          deleteVideoSessionEvidence({
            variables: {
              id: deletedVideoSessionEvidenceRow.id,
            },
          }).then(() => {
            refetch({ videoSessionId: videoSessionRow.id || "undefined" });
          });
        }
      });
    }
  };

  return (
    <Grid item xs={12}>
      <MaterialTable
        options={{
          draggable: false,
          padding: "default",
          pageSizeOptions: [5],
          toolbar: true,
          emptyRowsWhenPaging: false,
          detailPanelColumnAlignment: "right",
          detailPanelType: "single",
          rowStyle: evidenceRowData => ({
            backgroundColor:
              selectedVideoSessionEvidenceRow &&
              selectedVideoSessionEvidenceRow.id === evidenceRowData.id
                ? Colors.ITEM_SELECTED
                : "",
          }),
        }}
        data={tableData}
        icons={TableIcons}
        columns={[
          {
            title: t("videoSessionEvidenceDescription"),
            field: "description",
            headerStyle: {
              fontWeight: "bold",
            },
          },
          {
            title: t("evidenceUploadDate"),
            field: "createdAt",
            type: "date",
            render: rowData => (
              <>
                {rowData.createdAt ? (
                  <>
                    {moment(rowData.createdAt).format(
                      DateConstants.DEFAULT_DATE_FORMAT,
                    )}
                  </>
                ) : (
                  <>{t("cars:notAvailable")}</>
                )}
              </>
            ),
            headerStyle: {
              fontWeight: "bold",
            },
          },
          {
            title: t("evidenceLastUpdatedDate"),
            field: "updatedAt",
            type: "date",
            render: rowData => (
              <>
                {rowData.updatedAt ? (
                  <>
                    {moment(rowData.updatedAt).format(
                      DateConstants.DEFAULT_DATE_FORMAT,
                    )}
                  </>
                ) : (
                  <>{t("cars:notAvailable")}</>
                )}
              </>
            ),
            headerStyle: {
              fontWeight: "bold",
            },
          },
          {
            title: t("videoSessionEvidenceSubmitter"),
            field: "submitterName",
            headerStyle: {
              fontWeight: "bold",
            },
          },
        ]}
        title={t("videoSessionEvidence")}
        onRowClick={(event, rowData, togglePanel) => {
          setSelectedVideoSessionEvidenceRow(rowData);

          if (togglePanel) {
            togglePanel();
          }
        }}
        detailPanel={rowData => {
          return (
            <>
              {(keycloak?.hasRealmRole(Roles.auditor) ||
                keycloak?.hasRealmRole(Roles.administrator)) && (
                <Grid container className={classes.detailBackground}>
                  <Grid item xs={12}>
                    <AuditVideoSessionEvidenceDetails
                      videoSessionEvidenceRow={rowData}
                      handleRowEdited={handleRowEdit}
                      handleRowDeleted={handleRowDelete}
                    />
                  </Grid>
                </Grid>
              )}
            </>
          );
        }}
        actions={[
          {
            icon: () => <DownloadCloudIcon />,
            onClick: (event, evidenceRowData) => {
              if (evidenceRowData) {
                handleRowDownloadClick(evidenceRowData);
              }
            },
            tooltip: t("downloadVideoSessionEvidence"),
          },
        ]}
        localization={{
          header: {
            actions: " ",
          },
        }}
      />
    </Grid>
  );
};
