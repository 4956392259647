import { useQuery } from "@apollo/react-hooks";
import {
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Theme,
  Tooltip,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useKeycloak } from "@react-keycloak/web";
import DateConstants from "Constants/DateConstants";
import { DocumentType } from "Enums/DocumentType";
import { SubSystemType } from "Enums/SubSystemType";
import { SystemArea } from "Enums/SystemArea";
import {
  GET_FPSO_AREA_DOCUMENTS_BY_ID,
  GetFpsoAreaDocumentsByIdQueryResult,
  GetFpsoAreaDocumentsByIdQueryVariables,
} from "Graphql/FpsoQueries";
import { convertSelectedSubSystemsToArray } from "Logic/SubSystemOptions/ConvertSelectedSubSystemsToArray";
import MaterialTable from "material-table";
import { FpsoAreaDocument } from "Models/FpsoAreaDocument";
import { OptionType } from "Models/OptionType";
import moment from "moment";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MultiValue } from "react-select";
import { FileUtils } from "Utils/FileUtils";
import { FpsoAreaDocumentDetails } from "Views/Components/FpsoAreaDocuments/FpsoAreaDocumentDetails";
import { FpsoAreaDocumentUpload } from "Views/Components/FpsoAreaDocuments/FpsoAreaDocumentUpload";
import { DownloadCloudIcon } from "Views/Components/Icons/DownloadCloudIcon";
import { TableIcons } from "Views/Components/Icons/TableIcons";

interface Props {
  handleDocumentUpload(document: FpsoAreaDocument, file: File): void;
  handleDeleteDocument(document: FpsoAreaDocument): void;
  handleRowEdited(
    updatedDocumentRow: FpsoAreaDocument,
    updatedSubSystems: SubSystemType[],
    changeInSubSystems: boolean,
  ): void;
  readonly selectedSubSystems: MultiValue<OptionType> | undefined;
  uploadFormEnabled: boolean;
  handleAddClicked(): void;
  readonly fpsoAreaId: string;
  refetchDocuments: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingBottom: theme.spacing(1),
    },
    buttonText: {
      textTransform: "capitalize",
      color: "white",
    },
    buttonRow: {
      display: "flex",
      marginBottom: theme.spacing(2),
    },
    discardButtonRow: {
      display: "flex",
      justifyContent: "flex-end",
      marginBottom: theme.spacing(2),
    },
    dropdown: {
      display: "flex",
      marginBottom: theme.spacing(2),
      minWidth: 200,
    },
  }),
);

export const FpsoAreaDocumentList: FC<Props> = ({
  selectedSubSystems,
  handleDeleteDocument,
  handleRowEdited,
  handleDocumentUpload,
  handleAddClicked,
  uploadFormEnabled,
  fpsoAreaId,
  refetchDocuments,
}) => {
  const { t } = useTranslation("fpsos");
  const classes = useStyles();
  const { keycloak } = useKeycloak();

  const [tableData, setTableData] = useState<FpsoAreaDocument[]>([]);

  const selectedSubSystemsConversion = convertSelectedSubSystemsToArray(
    selectedSubSystems,
  );

  let fpsoAreaDocumentsWhereVariables = {};
  if (selectedSubSystemsConversion.selectedSubSystemsString) {
    fpsoAreaDocumentsWhereVariables = {
      fpsoAreaId: { _eq: fpsoAreaId },
      items_with_sub_systems: {
        subSystemType: {
          _in:
            selectedSubSystemsConversion.selectedSubSystemsSubSystemTypeArray,
        },
      },
    };
  } else {
    fpsoAreaDocumentsWhereVariables = { fpsoAreaId: { _eq: fpsoAreaId } };
  }

  const { data: fpsoAreaDocumentsInfo, refetch } = useQuery<
    GetFpsoAreaDocumentsByIdQueryResult,
    GetFpsoAreaDocumentsByIdQueryVariables
  >(GET_FPSO_AREA_DOCUMENTS_BY_ID, {
    fetchPolicy: "cache-and-network",
    variables: {
      id: fpsoAreaId || "undefined",
      whereVariables: fpsoAreaDocumentsWhereVariables,
    },
    //onCompleted: handleFpsoAreaDocumentsReceived,
  });

  const handleFpsoAreaDocumentsReceived = useCallback(() => {
    const data: FpsoAreaDocument[] = [];
    fpsoAreaDocumentsInfo?.fpsoArea.fpsoAreaDocuments.forEach(item => {
      data.push({
        ...item,
      });
    });
    setTableData(data);
      // eslint-disable-next-line
  }, [fpsoAreaDocumentsInfo]);

    useEffect(() => {
      handleFpsoAreaDocumentsReceived();
        // eslint-disable-next-line
    }, [fpsoAreaDocumentsInfo]);

  const refetchDocumentsFunction = useCallback(() => {
    refetch({
      id: fpsoAreaId || "undefined",
      whereVariables: fpsoAreaDocumentsWhereVariables,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // Disabled warnings here for now as including selectedSubSystemsSubSystemTypeArray as a
  // dependency causes an infinite loop, and other "solutions" for the
  // missing dependencies error for both useCallback and useEffect
  // result in a similar loop or just unexpected behaviour

  useEffect(() => {
    refetchDocumentsFunction();
  }, [selectedSubSystems, refetchDocuments]); // eslint-disable-line react-hooks/exhaustive-deps
  // Disabled warnings here for now as including selectedSubSystemsSubSystemTypeArray as a
  // dependency causes an infinite loop, and other "solutions" for the
  // missing dependencies error for both useCallback and useEffect
  // result in a similar loop or just unexpected behaviour

  const handleRowDownloadClick = (
    rowData: FpsoAreaDocument | FpsoAreaDocument[],
  ) => {
    let url: string;
    let fileName: string;
    if (Array.isArray(rowData)) {
      url = rowData[0].reference;
      fileName = `${rowData[0].documentNumber}-${rowData[0].name}-${rowData[0].revision}`;
    } else {
      url = rowData.reference;
      fileName = `${rowData.documentNumber}-${rowData.name}-${rowData.revision}`;
    }

    if (url) {
      FileUtils.downloadFileByUrl(url, fileName, `bearer ${keycloak?.token}`);
    }
  };

  return (
    <>
      <Paper>
        {uploadFormEnabled && (
          <>
            <Grid item xs={12} className={classes.discardButtonRow}>
              <Tooltip title={t<string>("files:tooltipDiscard")} arrow={true}>
                <IconButton onClick={handleAddClicked}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <FpsoAreaDocumentUpload
              handleDocumentUpload={handleDocumentUpload}
            />
          </>
        )}
        <MaterialTable
          options={{
            filtering: true,
            draggable: false,
            padding: "default",
            pageSizeOptions: [10, 20, 30],
            pageSize: 10,
            toolbar: true,
            search: true,
            emptyRowsWhenPaging: false,
            detailPanelType: "single",
            headerStyle: {
              zIndex: 0,
              fontWeight: "bold",
            },
          }}
          onRowClick={(event, rowData, togglePanel) => {
            if (togglePanel) {
              togglePanel();
            }
          }}
          title={
            fpsoAreaDocumentsInfo?.fpsoArea.fpso.name +
            " - " +
            fpsoAreaDocumentsInfo?.fpsoArea.areaName +
            ": " +
            t("fpsoAreaDocuments")
          }
          icons={TableIcons}
          data={tableData}
          columns={[
            {
              title: t("doc_name"),
              field: "name",
            },
            {
              title: t("doc_type"),
              field: "type",
              render: rowData => (
                <>
                  {rowData.type === DocumentType.other &&
                    t("files:documentTypeOther")}
                  {rowData.type === DocumentType.procedure &&
                    t("files:documentTypeProcedure")}
                  {rowData.type === DocumentType.report &&
                    t("files:documentTypeReport")}
                  {rowData.type === DocumentType.certificate &&
                    t("files:documentTypeCertificate")}
                  {rowData.type === DocumentType.drawing &&
                    t("files:documentTypeDrawing")}
                  {rowData.type === DocumentType.list &&
                    t("files:documentTypeList")}
                  {rowData.type === DocumentType.specification &&
                    t("files:documentTypeSpecification")}
                  {rowData.type === DocumentType.contractualDocument &&
                    t("files:documentTypeContractualDocument")}
                  {rowData.type === DocumentType.documentDataSheet &&
                    t("files:documentTypeDocumentDataSheet")}
                  {rowData.type === DocumentType.dataSheet &&
                    t("files:documentTypeDataSheet")}
                  {rowData.type === DocumentType.functionalDesign &&
                    t("files:documentTypeFunctionalDesign")}
                  {rowData.type === DocumentType.isometricDrawings &&
                    t("files:documentTypeIsometricDrawings")}
                  {rowData.type === DocumentType.inspectionDataSheet &&
                    t("files:documentTypeInspectionDataSheet")}
                  {rowData.type === DocumentType.minutesOfMeeting &&
                    t("files:documentTypeMinutesOfMeeting")}
                  {rowData.type === DocumentType.planningAndScheduling &&
                    t("files:documentTypePlanningAndScheduling")}
                  {rowData.type === DocumentType.processAndInstrumentDiagram &&
                    t("files:documentTypeProcessAndInstrumentDiagram")}
                  {rowData.type === DocumentType.technicalDataSheet &&
                    t("files:documentTypeTechnicalDataSheet")}
                  {rowData.type === DocumentType.technicalQuery &&
                    t("files:documentTypeTechnicalQuery")}
                </>
              ),
              lookup: {
                Other: t("files:documentTypeOther"),
                Procedure: t("files:documentTypeProcedure"),
                Report: t("files:documentTypeReport"),
                Certificate: t("files:documentTypeCertificate"),
                Drawing: t("files:documentTypeDrawing"),
                List: t("files:documentTypeList"),
                Specification: t("files:documentTypeSpecification"),
                Contractual_Document: t(
                  "files:documentTypeContractualDocument",
                ),
                Document_Data_Sheet: t("files:documentTypeDocumentDataSheet"),
                Data_Sheet: t("files:documentTypeDataSheet"),
                Functional_Design: t("files:documentTypeFunctionalDesign"),
                Isometric_Drawings: t("files:documentTypeIsometricDrawings"),
                Inspection_Data_Sheet: t(
                  "files:documentTypeInspectionDataSheet",
                ),
                Minutes_of_Meeting: t("files:documentTypeMinutesOfMeeting"),
                Planning_and_Scheduling: t(
                  "files:documentTypePlanningAndScheduling",
                ),
                Process_and_Instrument_Diagram: t(
                  "files:documentTypeProcessAndInstrumentDiagram",
                ),
                Technical_Data_Sheet: t("files:documentTypeTechnicalDataSheet"),
                Technical_Query: t("files:documentTypeTechnicalQuery"),
              },
            },
            {
              title: t("uploader"),
              field: "uploader.fullName",
            },
            {
              title: t("publish_date"),
              field: "publishDate",
              render: rowData => (
                <>
                  {rowData.publishDate && (
                    <>
                      {moment(rowData.publishDate).format(
                        DateConstants.DEFAULT_DATE_FORMAT,
                      )}
                    </>
                  )}
                </>
              ),
            },
            {
              title: t("systemArea"),
              field: "systemArea",
              render: rowData => (
                <>
                  {rowData.systemArea === SystemArea.other && t("other")}
                  {rowData.systemArea === SystemArea.vessel && t("vessel")}
                  {rowData.systemArea === SystemArea.electrical &&
                    t("electrical")}
                  {rowData.systemArea === SystemArea.instrument &&
                    t("instrument")}
                  {rowData.systemArea === SystemArea.mechanical &&
                    t("mechanical")}
                  {rowData.systemArea === SystemArea.hardware && t("hardware")}
                  {rowData.systemArea === SystemArea.piping && t("piping")}
                  {rowData.systemArea === SystemArea.welfare && t("welfare")}
                  {rowData.systemArea === SystemArea.process && t("process")}
                  {rowData.systemArea === SystemArea.safety && t("safety")}
                  {rowData.systemArea === SystemArea.liftingEquipment &&
                    t("liftingEquipment")}
                  {rowData.systemArea === SystemArea.wasteManagement &&
                    t("wasteManagement")}
                  {rowData.systemArea === SystemArea.chemicalsAndFuel &&
                    t("chemicalsAndFuel")}
                </>
              ),
              lookup: {
                Other: t("other"),
                Vessel: t("vessel"),
                Electrical: t("electrical"),
                Instrument: t("instrument"),
                Mechanical: t("mechanical"),
                Hardware: t("hardware"),
                Piping: t("piping"),
                Welfare: t("welfare"),
                Process: t("process"),
                Safety: t("safety"),
                Lifting_Equipment: t("liftingEquipment"),
                Waste_Management: t("wasteManagement"),
                Chemicals_and_Fuel: t("chemicalsAndFuel"),
              },
            },
          ]}
          actions={[
            {
              icon: () => <DownloadCloudIcon />,
              onClick: (event, rowData) => {
                if (rowData) {
                  handleRowDownloadClick(rowData);
                }
              },
              tooltip: t("download_doc"),
            },
          ]}
          localization={{
            header: {
              actions: " ",
            },
          }}
          detailPanel={documentData => {
            return (
              <FpsoAreaDocumentDetails
                fpsoAreaDocumentRow={documentData}
                handleDocumentDeleted={handleDeleteDocument}
                handleRowEdited={handleRowEdited}
                fpsoAreaName={fpsoAreaDocumentsInfo?.fpsoArea.areaName}
              />
            );
          }}
        />
      </Paper>
    </>
  );
};
