import MomentUtils from "@date-io/moment";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { KeycloakProvider } from "@react-keycloak/web";
import { keycloak } from "keycloak";
import { KeycloakInitOptions } from "keycloak-js";
import React, { FC } from "react";
import { Authentication } from "Views/Components/Authentication/Authentication";
import { MainPage } from "Views/MainPage/MainPage";
import "./App.scss";
import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { Roles } from "Enums/Roles";

const keycloakProviderInitConfig = {
  onLoad: "login-required",
  promiseType: "native",
} as KeycloakInitOptions;

const httpLink = new HttpLink({ uri: process.env.REACT_APP_HASURA_URL });
const authLink = setContext((_, { headers }) => {
  const token = keycloak.token;
  const tokenParsed = keycloak.tokenParsed as any;
  const relevantRoles = 
    tokenParsed?.user_realm_roles?.filter((x: string) => x !== Roles.biUser);

  return {
    headers: {
      ...headers,
      "X-Hasura-Role": relevantRoles ? relevantRoles[0] : "",
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

const customTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#2C58A4",
    },
  },
  typography: {
    fontFamily: '"Red Hat Display", sans-serif',
    h4: {
      fontSize: 24,
      fontStyle: "italic",
    },
    h5: {
      fontSize: 20,
    },
    h6: {
      fontSize: 24,
    },
    subtitle1: {
      fontSize: 19,
      fontWeight: "bold",
    },
    body1: {
      fontSize: 16,
    },
    body2: {
      fontSize: 16,
    },
    subtitle2: {
      fontSize: 14,
      fontStyle: "italic",
    },
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontFamily: '"Red Hat Display", sans-serif',
        fontSize: 14,
        color: "black",
        backgroundColor: "white",
        boxShadow: "0 20px 80px 0",
      },
      arrow: {
        color: "white",
      },
    },
  },
});

export const App: FC = () => {
  return (
    <KeycloakProvider
      keycloak={keycloak}
      initConfig={keycloakProviderInitConfig}
    >
      <ApolloProvider client={apolloClient}>
        <ThemeProvider theme={customTheme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Authentication>
              <CssBaseline />
              <MainPage />
            </Authentication>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </ApolloProvider>
    </KeycloakProvider>
  );
};
