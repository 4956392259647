import { useQuery } from "@apollo/react-hooks";
import { CircularProgress } from "@material-ui/core";
import {
  GET_ALL_FPSOS_SCORE_OVER_TIME,
  GetAllFpsosScoreOverTimeResult,
} from "Graphql/FpsoQueries";
import moment from "moment";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NameToColor } from "Utils/NameToColor";
import { BaseLineChart } from "Views/Components/ManagementSummary/BaseLineChart";

export const ScoreVsTimeChart: FC = () => {
  const { t } = useTranslation("kpis");

  const [chartData, setChartData] = useState<Plotly.Data[]>([]);
  const [auditScoreDataCollected, setAuditScoreDataCollected] = useState(false);
  const [sortedDates, setSortedDates] = useState<Date[]>([]);

  const title = t("auditScoresOverTimeTitle");
      
  const { loading: fpsosLoading, error: fpsosError, data: fpsoData } = useQuery<
  GetAllFpsosScoreOverTimeResult
>(GET_ALL_FPSOS_SCORE_OVER_TIME, {
  //onCompleted: handleFpsosReceived,
});

  const handleFpsosReceived = useCallback(() => {
    const plotData: Plotly.Data[] = [];
    const allDates: Date[] = [];

    fpsoData?.fpsos.forEach(fpsoPoint => {
      fpsoPoint.fpsoAreas.forEach(fpsoAreaPoint => {
        const xdata: Date[] = [];
        const ydata: number[] = [];
        const auditReferences: string[] = [];

        const companyName = fpsoPoint.block.owner.companyName;
        const lineColor = NameToColor.intToRGB(
          NameToColor.nameToHashCode(companyName),
        );

        fpsoAreaPoint?.auditScoresOverTime.forEach(item => {
          xdata.push(item.createdAt);
          ydata.push(item.auditScore);
          auditReferences.push(item.auditReference);
          allDates.push(item.createdAt);
        });

        if (fpsoAreaPoint.currentAudit !== null) {
          xdata.push(moment().toDate());
          ydata.push(fpsoAreaPoint.currentAudit.auditScore);
          auditReferences.push(fpsoAreaPoint.currentAudit.auditReference);
        }

        const trace = {
          x: xdata,
          y: ydata,
          mode: "lines+markers",
          marker: {
            color: lineColor,
          },
          line: {
            color: lineColor,
          },
          name: fpsoPoint.name + " - " + fpsoAreaPoint.areaName,
          connectgaps: true,
          hovertemplate:
            "<i>" +
            t("auditScore") +
            ":</i> %{y}" +
            "<br><i>" +
            t("auditRef") +
            ":</i> %{text}" +
            "<br><i>" +
            t("operator") +
            ":</i> " +
            companyName,
          text: auditReferences,
        };
        plotData.push(trace as Plotly.Data);
      });
    });

    setChartData(plotData);
    setAuditScoreDataCollected(!auditScoreDataCollected);

    const allDatesSorted = allDates.sort((a, b) => {
      if (a > b) {
        return 1;
      }
      if (a < b) {
        return -1;
      }
      return 0;
    });
    setSortedDates(allDatesSorted);
    
      // eslint-disable-next-line
  }, [fpsoData]);

    useEffect(() => {
      if (fpsoData) {
        handleFpsosReceived();
      }
      // eslint-disable-next-line
    }, [fpsoData]);



  return (
    <>
      {fpsosLoading ? (
        <CircularProgress />
      ) : (
        <div>
          {auditScoreDataCollected && !fpsosError ? (
            <BaseLineChart
              data={chartData}
              titleString={title}
              sortedDates={sortedDates}
            />
          ) : (
            <div>{t("errors:scoreVsTimeChartFetchError")}</div>
          )}
        </div>
      )}
    </>
  );
};
