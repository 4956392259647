import { useQuery } from "@apollo/react-hooks";
import {
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import Colors from "Constants/Colors";
import DateConstants from "Constants/DateConstants";
import LanguageCodes from "Constants/LanguageCodes";
import { CarClass } from "Enums/CarClass";
import { CarStatus } from "Enums/CarStatus";
import { PerformanceIndicator } from "Enums/PerformanceIndicator";
import {
  GET_ARCHIVED_CARS_BY_AUDIT_SNAPSHOT,
  GetArchivedCarsByAuditSnapshotQueryResult,
  GetArchivedCarsByAuditSnapshotQueryVariables,
} from "Graphql/CarQueries";
import i18next from "i18next";
import { convertSelectedSubSystemsToArray } from "Logic/SubSystemOptions/ConvertSelectedSubSystemsToArray";
import MaterialTable from "material-table";
import { Audit } from "Models/Audit";
import { CarRow } from "Models/CarRow";
import { OptionType } from "Models/OptionType";
import { CarTranslation } from "Models/Translations/CarTranslation";
import moment from "moment";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MultiValue } from "react-select";
import { CarDetails } from "Views/Components/CarDetails/CarDetails";
import { CarEvidenceDocuments } from "Views/Components/CarEvidenceDocuments/CarEvidenceDocuments";
import { BoxIcon } from "Views/Components/Icons/BoxIcon";
import { CircleIcon } from "Views/Components/Icons/CircleIcon";
import { DiamondIcon } from "Views/Components/Icons/DiamondIcon";
import { TableIcons } from "Views/Components/Icons/TableIcons";
import { TriangleIcon } from "Views/Components/Icons/TriangleIcon";

interface Props {
  readonly audit: Audit;
  readonly isArchived: boolean;
  readonly selectedSubSystems: MultiValue<OptionType> | undefined;
  readonly fpsoAreaName: string | undefined;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: Colors.ITEM_SELECTED,
    },
  }),
);

export const ArchivedCarList: FC<Props> = ({
  audit,
  isArchived,
  selectedSubSystems,
  fpsoAreaName,
}) => {
  const { t } = useTranslation(["audit", "cars", "errors"]);
  const classes = useStyles();

  const [tableData, setTableData] = useState<CarRow[]>([]);
  const [selectedCarRow, setSelectedCarRow] = useState<CarRow>();



  const selectedSubSystemsConversion = convertSelectedSubSystemsToArray(
    selectedSubSystems,
  );

  let archivedCarListWhereVariables = {};
  if (selectedSubSystemsConversion.selectedSubSystemsString) {
    archivedCarListWhereVariables = {
      auditSnapshotId: { _eq: audit.id },
      items_with_sub_systems: {
        subSystemType: {
          _in:
            selectedSubSystemsConversion.selectedSubSystemsSubSystemTypeArray,
        },
      },
    };
  } else {
    archivedCarListWhereVariables = { auditSnapshotId: { _eq: audit.id } };
  }

  const { loading: carsLoading, error: carsError, data: carData } = useQuery<
    GetArchivedCarsByAuditSnapshotQueryResult,
    GetArchivedCarsByAuditSnapshotQueryVariables
  >(GET_ARCHIVED_CARS_BY_AUDIT_SNAPSHOT, {
    fetchPolicy: "cache-and-network",
    variables: {
      auditSnapshotId: audit.id || "undefined",
      whereVariables: archivedCarListWhereVariables,
    },
    //onCompleted: handleCarsReceived,
  });

  const handleCarsReceived = useCallback(() => {
    const data: CarRow[] = [];
    carData?.carSnapshots.forEach(item => {
      const carReferenceSplits = item.carReference.split("-");
      const carNumber = carReferenceSplits
        .slice(carReferenceSplits.length - 3)
        .join("-");
      data.push({
        ...item,
        carReference: carNumber,
        addedBy: item.reporter.fullName,
        addedById: item.reporter.id,
        componentsAffected: item.componentsAffected || t("cars:notAvailable"),
        originalCarId: item.carId,
        operatorName: item.fpsoArea.fpso.block.owner.companyName as string,
        englishTranslation:
          (item.englishTranslation[0] as CarTranslation) || [],
        portugueseTranslation:
          (item.portugueseTranslation[0] as CarTranslation) || [],
      });
    });
    setTableData(data);
      // eslint-disable-next-line
  }, [carData, t]);

    useEffect(() => {
      handleCarsReceived();
        // eslint-disable-next-line
    }, [carData]);

  if (carsError) {
    return (
      <Grid item xs={12}>
        <Typography variant="body1" color="error">
          {t("errors:carsFetchError")}
        </Typography>
      </Grid>
    );
  }

  return (
    <Paper>
      <MaterialTable
        isLoading={carsLoading}
        options={{
          exportButton: false,
          draggable: false,
          padding: "default",
          pageSizeOptions: [10, 20, 30],
          pageSize: 10,
          emptyRowsWhenPaging: false,
          detailPanelColumnAlignment: "right",
          detailPanelType: "single",
          rowStyle: rowData => ({
            backgroundColor:
              selectedCarRow && selectedCarRow.id === rowData.id
                ? Colors.ITEM_SELECTED
                : "",
            textDecoration: rowData.isDeleted ? "line-through" : "",
            color: rowData.isDeleted ? "rgba(0, 0, 0, 0.38)" : "",
          }),
          headerStyle: {
            zIndex: 0,
            fontWeight: "bold",
          },
        }}
        columns={[
          {
            title: t("cars:carReference"),
            field: "carReference",
          },
          {
            title: t("cars:description"),
            field:
              i18next.language === LanguageCodes.ENGLISH
                ? "englishTranslation.description"
                : "portugueseTranslation.description",
          },
          {
            title: t("cars:class"),
            field: "class",
            render: rowData => (
              <>
                {rowData.class === CarClass.comment && t("cars:classComment")}
                {rowData.class === CarClass.minor && t("cars:classMinor")}
                {rowData.class === CarClass.major && t("cars:classMajor")}
                {rowData.class === CarClass.critical && t("cars:classCritical")}
              </>
            ),
          },
          {
            title: t("cars:status"),
            field: "status",
            render: rowData => (
              <>
                {rowData.status === CarStatus.open && t("cars:statusOpen")}
                {rowData.status === CarStatus.closed && t("cars:statusClosed")}
              </>
            ),
          },
          {
            title: t("cars:actionRequired"),
            field: "actionRequired",
            render: rowData => (
              <>
                <div>
                  {rowData.operatorActionRequired && rowData.operatorName}
                </div>
                <div>{rowData.anpgActionRequired && t("cars:anpgName")}</div>
                <div>{rowData.auditorActionRequired && t("cars:ogcName")}</div>
              </>
            ),
          },
          {
            title: t("cars:performanceIndicator"),
            field: "performanceIndicator",
            render: rowData => (
              <>
                {rowData.performanceIndicator ===
                  PerformanceIndicator.yellow && (
                  <Tooltip
                    title={t<string>("cars:yellowCircleTooltip")}
                    placement="bottom"
                    arrow={true}
                  >
                    <span>
                      <CircleIcon
                        strokeColor={Colors.PERFORMANCE_INDICATOR_YELLOW}
                        fillColor={"transparent"}
                      />
                    </span>
                  </Tooltip>
                )}
                {rowData.performanceIndicator === PerformanceIndicator.blue && (
                  <Tooltip
                    title={t<string>("cars:blueSquareTooltip")}
                    placement="bottom"
                    arrow={true}
                  >
                    <span>
                      <BoxIcon
                        strokeColor={Colors.PERFORMANCE_INDICATOR_BLUE}
                        fillColor={"transparent"}
                      />
                    </span>
                  </Tooltip>
                )}
                {rowData.performanceIndicator === PerformanceIndicator.red && (
                  <Tooltip
                    title={t<string>("cars:redTriangleTooltip")}
                    placement="bottom"
                    arrow={true}
                  >
                    <span>
                      <TriangleIcon
                        strokeColor={Colors.PERFORMANCE_INDICATOR_RED}
                        fillColor={"transparent"}
                      />
                    </span>
                  </Tooltip>
                )}
                {rowData.performanceIndicator === PerformanceIndicator.grey && (
                  <Tooltip
                    title={t<string>("cars:greyDiamondTooltip")}
                    placement="bottom"
                    arrow={true}
                  >
                    <span>
                      <DiamondIcon
                        strokeColor={Colors.PERFORMANCE_INDICATOR_GREY}
                        fillColor={"transparent"}
                      />
                    </span>
                  </Tooltip>
                )}
                {rowData.status === CarStatus.closed && (
                  <CheckIcon htmlColor={Colors.CAR_CLOSED} />
                )}
              </>
            ),
          },
          {
            title: t("cars:dateAdded"),
            field: "createdAt",
            type: "date",
            render: rowData => (
              <>
                {rowData.createdAt ? (
                  <>
                    {moment(rowData.createdAt).format(
                      DateConstants.DEFAULT_DATE_FORMAT,
                    )}
                  </>
                ) : (
                  <>{t("cars:notAvailable")}</>
                )}
              </>
            ),
          },
          { title: t("cars:addedBy"), field: "addedBy" },
          {
            title: t("cars:agreedVerificationDate"),
            field: "agreedVerificationDate",
            type: "date",
            render: rowData => (
              <>
                {rowData.agreedVerificationDate ? (
                  <>
                    {moment(rowData.agreedVerificationDate).format(
                      DateConstants.DEFAULT_DATE_FORMAT,
                    )}
                  </>
                ) : (
                  <>{t("cars:notAvailable")}</>
                )}
              </>
            ),
          },
          {
            title: t("cars:actualVerificationDate"),
            field: "actualVerificationDate",
            type: "date",
            render: rowData => (
              <>
                {rowData.actualVerificationDate ? (
                  <>
                    {moment(rowData.actualVerificationDate).format(
                      DateConstants.DEFAULT_DATE_FORMAT,
                    )}
                  </>
                ) : (
                  <>{t("cars:notAvailable")}</>
                )}
              </>
            ),
          },
        ]}
        onRowClick={(event, rowData, togglePanel) => {
          setSelectedCarRow(rowData);

          if (togglePanel) {
            togglePanel();
          }
        }}
        icons={TableIcons}
        data={tableData}
        title={audit.auditReference}
        detailPanel={rowData => {
          return (
            <Grid container className={classes.root}>
              <Grid item xs={6}>
                <CarDetails
                  carRow={rowData}
                  isActive={false}
                  isLocked={false}
                  fpsoAreaName={fpsoAreaName}
                />
              </Grid>
              <Grid item xs={6}>
                <CarEvidenceDocuments
                  carRow={rowData}
                  fpsoId={audit.fpsoId}
                  fpsoAreaId={audit.fpsoAreaId}
                  auditId={audit.id}
                  isActive={false}
                  isLocked={false}
                  isArchived={isArchived}
                />
              </Grid>
            </Grid>
          );
        }}
      />
    </Paper>
  );
};
