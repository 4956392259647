import { useQuery } from "@apollo/react-hooks";
import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import Colors from "Constants/Colors";
import {
  GET_CAR_STATUS_RATIO_BY_FPSO_AREA,
  GetCarStatusRatioByFpsoAreaResult,
  GetCarStatusRatioByFpsoAreaVariables,
} from "Graphql/FpsoQueries";
import { FPSOArea } from "Models/FPSOArea";
import { FC, useCallback, useEffect, useState } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { DoublePieChart } from "Views/Components/FpsoOverview/Graphs/DoublePieChart";

interface Props {
  readonly fpsoArea: FPSOArea;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    errorContainer: {
      padding: theme.spacing(2),
    },
  }),
);

export const CurrentOpenCloseRatioChart: FC<Props> = ({ fpsoArea }) => {
  const { t } = useTranslation("cars");
  const classes = useStyles();

  const [chartData, setChartData] = useState<Plotly.Data[]>([]);

  const { data } = useQuery<
    GetCarStatusRatioByFpsoAreaResult,
    GetCarStatusRatioByFpsoAreaVariables
  >(GET_CAR_STATUS_RATIO_BY_FPSO_AREA, {
    variables: {
      fpsoAreaId: fpsoArea.id,
    },
    // onCompleted: handleCarRatioDataReceived,
  });

  const handleCarRatioDataReceived = useCallback(() => {
    const mainKeys: string[] = [];
    const mainValues: number[] = [];
    const mainSectionColors: string[] = [];

    data?.carStatusRatios.forEach(ratio => {
      mainSectionColors.push(
        ratio.status === "OPEN"
          ? Colors.STATUS_OPEN
          : ratio.status === "CLOSED"
          ? Colors.STATUS_CLOSED
          : "",
      );
      const newRatio = { ...ratio };

      newRatio.status = 
        ratio.status === "OPEN" || 
        ratio.status === "OPEN_EVIDENCE_AWAITING_REVIEW" || 
        ratio.status === "OPEN_EVIDENCE_REQUIRES_OPERATOR_REVISION" || 
        ratio.status === "OPEN_EVIDENCE_REVIEWED" 
          ? t("cars:statusOpen") 
          : ratio.status === "CLOSED" 
            ? t("cars:statusClosed") 
            : ratio.status;

      mainKeys.push(newRatio.status);
      mainValues.push(newRatio.amount);
    });

    const carStatusTrace = {
      values: mainValues,
      labels: mainKeys,
      type: "pie",
      name: "Car status ratio",
      textfont: {
        family: '"Red Hat Display", sans-serif',
        size: 20,
        color: Colors.DARK_GREY,
      },
      textinfo: "value",
      showlegend: true,
      automargin: true,
      sort: false,
      marker: {
        colors: mainSectionColors,
        line: {
          color: Colors.WHITE,
          width: [4, 4, 4, 4, 4],
        },
      },
      hoverinfo: "label+value+percent",
      domain: { column: 0 },
    };

    const secondaryKeys: string[] = [];
    const secondaryValues: number[] = [];
    const secondarySectionColors: string[] = [];

    data?.carAnpgActionRequiredRatio.forEach(anpgRatio => {
      if (anpgRatio.action_required === true) {
        secondarySectionColors.push(
          anpgRatio.action_required === true ? Colors.ANPG_ACTION_REQUIRED : "",
        );
        secondaryKeys.push("Actions Required by ANPG");
        secondaryValues.push(anpgRatio.amount);
      }
    });

    data?.carAuditorActionRequiredRatio.forEach(auditorRatio => {
      if (auditorRatio.action_required === true) {
        secondarySectionColors.push(
          auditorRatio.action_required === true
            ? Colors.AUDITOR_ACTION_REQUIRED
            : "",
        );
        secondaryKeys.push("Actions Required by OGC");
        secondaryValues.push(auditorRatio.amount);
      }
    });

    data?.carOperatorActionRequiredRatio.forEach(operatorRatio => {
      if (operatorRatio.action_required === true) {
        secondarySectionColors.push(
          operatorRatio.action_required === true
            ? Colors.OPERATOR_ACTION_REQUIRED
            : "",
        );
        secondaryKeys.push(
          "Actions Required by " + fpsoArea.fpso.block.owner.companyName,
        );
        secondaryValues.push(operatorRatio.amount);
      }
    });

    const secondaryCarStatusTrace = {
      values: secondaryValues,
      labels: secondaryKeys,
      type: "pie",
      name: "Actions Required:",
      textfont: {
        family: '"Red Hat Display", sans-serif',
        size: 19,
        color: Colors.DARK_GREY,
      },
      textinfo: "value",
      showlegend: true,
      automargin: true,
      sort: false,
      marker: {
        colors: secondarySectionColors,
        line: {
          color: Colors.WHITE,
          width: [4, 4, 4, 4, 4],
        },
      },
      hoverinfo: "label+value",
      domain: { x: [0.45, 1], y: [0.45, 1] },
    };

    const plotData: Plotly.Data[] = [];
    plotData.push(carStatusTrace as Plotly.Data);
    plotData.push(secondaryCarStatusTrace as Plotly.Data);

    setChartData(plotData);

    // eslint-disable-next-line
  }, [data]);

    useEffect(() => {
      handleCarRatioDataReceived();
      // eslint-disable-next-line
    }, [data]);

  const titletext = t("cars:carStatusRatio");
  const subtitletext = t("cars:allCars");
  const subtitlepositionY = 0.9;
  const secondarySubtitleText = t("numActionsRequired");
  const secondarySubtitlePositionY = 0.9;

  if (data?.carStatusRatios && data?.carStatusRatios.length !== 0) {
    return (
      <DoublePieChart
        data={chartData}
        titleText={titletext}
        subtitleText={subtitletext}
        subtitlePositionY={subtitlepositionY}
        secondarySubtitleText={secondarySubtitleText}
        secondarySubtitlePositionY={secondarySubtitlePositionY}
      />
    );
  }

  if (data?.carStatusRatios.length === 0) {
    return (
      <div className={classes.errorContainer}>
        <Typography>{t("errors:carStatusRatioChartFetchNoData")}</Typography>
      </div>
    );
  }

  return (
    <div>
      <Typography>{t("errors:carStatusRatioChartFetchError")}</Typography>
    </div>
  );
};
