import { useQuery } from "@apollo/react-hooks";
import { CircularProgress } from "@material-ui/core";
import {
  GET_ALL_AUDIT_CURRENT_SCORES,
  GetAllAuditCurrentScoresResult,
} from "Graphql/AuditQueries";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NameToColor } from "Utils/NameToColor";
import { BaseBarChart } from "Views/Components/ManagementSummary/BaseBarChart";

export const CurrentScoreCompareChart: FC = () => {
  const { t } = useTranslation("kpis");

  const [chartData, setChartData] = useState<Plotly.Data[]>([]);
  const [auditScoreDataCollected, setAuditScoreDataCollected] = useState(false);

  const title = t("currentAuditScoresTitle");
  const yAxisTitle = t("auditScore");
  const xAxisTitle = t("fpsoNames");

  const { loading: auditLoading, data: auditData } = useQuery<
  GetAllAuditCurrentScoresResult
>(GET_ALL_AUDIT_CURRENT_SCORES, {
  //onCompleted: handleAuditsReceived,
});

  const handleAuditsReceived = useCallback(() => {
    const plotData: Plotly.Data[] = [];
    const companyNames: string[] = [];

    auditData?.currentAuditScores.forEach(item => {
      const xdata: string[] = [];
      const ydata: number[] = [];
      const barColors: string[] = [];

      xdata.push(item.fpso_area.fpso.name + " - " + item.fpso_area.areaName);

      ydata.push(item.auditScore);
      barColors.push(
        NameToColor.intToRGB(
          NameToColor.nameToHashCode(
            item.fpso_area.fpso.block.owner.companyName,
          ),
        ),
      );

      const legendState = !companyNames.includes(
        item.fpso_area.fpso.block.owner.companyName,
      );

      companyNames.push(item.fpso_area.fpso.block.owner.companyName);

      const trace = {
        x: xdata,
        y: ydata,
        name: item.fpso_area.fpso.block.owner.companyName,
        legendgroup: item.fpso_area.fpso.block.owner.companyName,
        visible: true,
        showlegend: legendState,
        type: "bar",
        marker: {
          color: barColors,
        },
      };

      plotData.push(trace as Plotly.Data);
    });

    setChartData(plotData);
    setAuditScoreDataCollected(auditScoreDataCollected => !auditScoreDataCollected);

      // eslint-disable-next-line
  }, [auditData]);

    useEffect(() => {
      if (auditData) {
        handleAuditsReceived();
      }
      // eslint-disable-next-line
    }, [auditData]);

  return (
    <>
      {auditLoading ? (
        <CircularProgress />
      ) : (
        <div>
          {auditScoreDataCollected ? (
            <>
              <BaseBarChart
                data={chartData}
                titleString={title}
                xAxisTitle={xAxisTitle}
                yAxisTitle={yAxisTitle}
              />
            </>
          ) : (
            <div>{t("errors:currentAuditScoresFetchError")}</div>
          )}
        </div>
      )}
    </>
  );
};
