import { useQuery } from "@apollo/react-hooks";
import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import Colors from "Constants/Colors";
import {
  GET_OPEN_CAR_PERFORMANCE_INDICATOR_RATIO_BY_FPSO_AREA,
  GetOpenCarPerformanceIndicatorRatioByFpsoAreaResult,
  GetOpenCarPerformanceIndicatorRatioByFpsoAreaVariables,
} from "Graphql/FpsoQueries";
import { FPSOArea } from "Models/FPSOArea";
import { FC, useCallback, useEffect, useState } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { BasePieChart } from "Views/Components/FpsoOverview/Graphs/BasePieChart";

interface Props {
  readonly fpsoArea: FPSOArea;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    errorContainer: {
      padding: theme.spacing(2),
    },
  }),
);

export const OpenCarPerformanceIndicatorRatioChart: FC<Props> = ({
  fpsoArea,
}) => {
  const { t } = useTranslation("cars");
  const classes = useStyles();

  const [chartData, setChartData] = useState<Plotly.Data[]>([]);

  const { data } = useQuery<
    GetOpenCarPerformanceIndicatorRatioByFpsoAreaResult,
    GetOpenCarPerformanceIndicatorRatioByFpsoAreaVariables
  >(GET_OPEN_CAR_PERFORMANCE_INDICATOR_RATIO_BY_FPSO_AREA, {
    variables: {
      fpsoAreaId: fpsoArea.id,
    },
    //onCompleted: handleCarRatioDataReceived,
  });

  const handleCarRatioDataReceived = useCallback(() => {
    const keys: string[] = [];
    const values: number[] = [];
    const sectionColors: string[] = [];

    data?.openCarPerformanceIndicatorRatios.forEach(ratio => {
      keys.push(
        ratio.performance_indicator === "Grey"
          ? t("noDeadline")
          : ratio.performance_indicator === "Blue"
          ? t("inProgress")
          : ratio.performance_indicator === "Yellow"
          ? t("deadlineShifted")
          : ratio.performance_indicator === "Red"
          ? t("deadlineExceeded")
          : "",
      );
      values.push(ratio.amount);
      sectionColors.push(
        ratio.performance_indicator === "Grey"
          ? Colors.PERFORMANCE_INDICATOR_GREY
          : ratio.performance_indicator === "Blue"
          ? Colors.PERFORMANCE_INDICATOR_BLUE
          : ratio.performance_indicator === "Yellow"
          ? Colors.PERFORMANCE_INDICATOR_YELLOW
          : ratio.performance_indicator === "Red"
          ? Colors.PERFORMANCE_INDICATOR_RED
          : "",
      );
    });

    const openCarPerformanceIndicatorTrace = {
      values,
      labels: keys,
      type: "pie",
      name: "Open Car Performance Indicator ratio",
      textfont: {
        family: '"Red Hat Display", sans-serif',
        size: 20,
        color: Colors.DARK_GREY,
      },
      textinfo: "value",
      showlegend: true,
      sort: false,
      marker: {
        colors: sectionColors,
        line: {
          color: Colors.WHITE,
          width: [4, 4, 4, 4],
        },
      },
    };

    const plotData: Plotly.Data[] = [];
    plotData.push(openCarPerformanceIndicatorTrace as Plotly.Data);
    setChartData(plotData);

      // eslint-disable-next-line
  }, [data]);

    useEffect(() => {
      handleCarRatioDataReceived();
        // eslint-disable-next-line
    }, [data]);

  const titletext = t("openCarPerformanceIndicatorRatio");

  if (
    data?.openCarPerformanceIndicatorRatios &&
    data?.openCarPerformanceIndicatorRatios.length !== 0
  ) {
    return <BasePieChart data={chartData} titleText={titletext} />;
  }

  if (data?.openCarPerformanceIndicatorRatios.length === 0) {
    return (
      <div className={classes.errorContainer}>
        <Typography>
          {t("errors:carOpenPerformanceIndicatorRatioChartFetchNoData")}
        </Typography>
      </div>
    );
  }

  return (
    <div className={classes.errorContainer}>
      {" "}
      <Typography>
        {t("errors:carPerformanceIndicatorRatioChartFetchError")}
      </Typography>
    </div>
  );
};
